<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" rowspan="2" colspan="3">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="9">
            Invitation Letter Application of Sinopec Nanjing Engineering Middle
            East Co., Ltd
            <br />
            中石化南京工程中东公司访问签证邀请函办理申请表
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2" colspan="3">
            Company Name of Invitees<br />
            受邀人所属公司信息
          </td>
          <td class="text-center">
            Company Name in English <br />公司英文名称
          </td>
          <td class="text-center" colspan="2">
            <el-input
              v-model="CompanyNameinEnglish"
              placeholder="公司英文名称"
            />
          </td>
          <td class="text-center">Company CR NO <br />营业执照号</td>
          <td class="text-center">
            <el-input v-model="CompanyCRNO" placeholder="营业执照号" />
          </td>
          <td class="text-center">Invite Project Name <br />邀请项目名称</td>
          <td class="text-center">
            <el-select
              v-model="ProjectID"
              @change="getProjectsChildrenfa"
              placeholder="请选择"
            >
              <el-option
                v-for="item in projectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            Invite SubProject Name<br />邀请分项目名称
          </td>
          <td class="text-center">
            <el-select v-model="SubProjectID" placeholder="请选择">
              <el-option
                v-for="item in subProjectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">Invite Working Unit<br />邀请用工单位</td>
          <td class="text-center">
            <el-select v-model="CompanyID" placeholder="请选择">
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center">Business scope<br />营业范围（英文）</td>
          <td class="text-center" colspan="5">
            <el-input
              type="textarea"
              :rows="2"
              v-model="BusinessScope"
              placeholder="营业范围（英文）"
            />
          </td>
          <td class="text-center">
            Invitation Puporse <br />
            来访原因
          </td>
          <td class="text-center" colspan="4">
            <el-input
              type="textarea"
              :rows="2"
              v-model="InvitationPuporse"
              placeholder="来访原因"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            Visit Visa Requirement<br />邀请函所涉签证需求
          </td>
          <td class="text-center" colspan="2">
            Place of Visa Application<br />签证办理地点
          </td>
          <td class="text-center" colspan="2">
            <el-input
              v-model="PlaceofVisaApplication"
              placeholder="签证办理地点"
            />
          </td>
          <td class="text-center" colspan="2">
            Valid Duration<br />签证有效期
          </td>
          <td class="text-center" colspan="2">
            <el-select v-model="ValidDuration" placeholder="请选择">
              <el-option
                v-for="item in validDurationOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">Single/Multiple Entry<br />入境次数</td>
          <td class="text-center" colspan="2">
            <el-select v-model="SingleMultipleEntry" placeholder="请选择">
              <el-option
                v-for="item in singleMultipleEntryOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td colspan="14" class="text-center">
            Information of Employee Relatives 员工家属基本信息
          </td>
        </tr>
        <tr>
          <td class="text-center">
            S/N<br />
            序号
          </td>
          <td class="text-center underline-text">
            Employee ID<br />
            员工编码
          </td>
          <td class="text-center">Name in Chinese<br />员工姓名</td>
          <td class="text-center">
            Name in English<br />
            拼音姓名
          </td>
          <td class="text-center">
            Gender<br />
            性别
          </td>
          <td class="text-center">
            Nationality<br />
            国籍
          </td>
          <td class="text-center">
            Date of Birth<br />
            出生日期
          </td>
          <td class="text-center">
            Age<br />
            年龄
          </td>
          <td class="text-center">
            Religion<br />
            宗教信仰
          </td>
          <td class="text-center" colspan="2">Job Profession<br />岗位名称</td>
          <td class="text-center">
            Passport No<br />
            护照号
          </td>
          <td class="text-center">
            PP Expiry Date<br />
            护照有效期
          </td>
          <td class="text-center">
            Employment Type <br />
            用工形式
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            <el-input
              @input="changeEmployeeID(index)"
              @blur="changeEmployeeID(index)"
              v-model="item.EmployeeID"
              placeholder="员工编码 "
            />
          </td>
          <td class="text-center">
            <!-- <spantem v-if="i.isUser && item.NameinEnglish">
              {{ item.NameinEnglish }}</span
            > -->
            <el-input v-model="item.NameinChinese" placeholder="员工姓名" />
          </td>
          <td class="text-center">
            <!-- <spantem v-if="i.isUser && item.NameinEnglish">
              {{ item.NameinEnglish }}</span
            > -->
            <el-input v-model="item.NameinEnglish" placeholder="拼音姓名" />
          </td>
          <td class="text-center">
            <!-- <span v-if="item.isUser && item.Gender">{{
              item.Gender === 1 ? "男" : "女"
            }}</span> -->
            <el-select v-model="item.Gender" placeholder="请选择">
              <el-option
                v-for="item in genderList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>

          <td class="text-center">
            <!-- <span v-if="item.isUser && item.Nationality">
              {{ item.Nationality | nationalityFormater(nationalityList) }}
            </span> -->
            <el-select v-model="item.Nationality" placeholder="请选择">
              <el-option
                v-for="item in nationalityList"
                :key="item.ID"
                :label="item.CountryNameCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>

          <td class="text-center">
            <!-- <span v-if="item.isUser && item.DateofBirth">
              {{ item.DateofBirth | tableDateFrom }}
            </span> -->
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="item.DateofBirth"
              type="date"
              placeholder="生日"
              @change="changeDateofBirth(index)"
            >
            </el-date-picker>
          </td>
          <td class="text-center">{{ item.Age }}</td>

          <td class="text-center">
            <el-select v-model="item.Religion" placeholder="请选择">
              <el-option
                v-for="item in religionsList"
                :key="item.ID"
                :label="item.ReligionCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="2">
            <!-- <span v-if="item.isUser && item.JobProfession">
              {{ item.JobProfession | positionFormater(positionList) }}
            </span> -->

            <el-select v-model="item.JobProfession" placeholder="请选择">
              <el-option
                v-for="item in positionList"
                :key="item.ID"
                :label="item.PositionsCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <!-- <span v-if="item.isUser && item.PassportCode">
              {{ item.PassportCode }}
            </span> -->
            <el-input v-model="item.PassportCode" placeholder="护照号" />
          </td>
          <td class="text-center">
            <!-- <span v-if="item.isUser && item.PPValidDate">
              {{ item.PPValidDate | tableDateFrom }}
            </span> -->
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="item.PPValidDate"
              type="date"
              placeholder="有效期"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <!-- <span v-if="item.isUser && item.EmploymentType">
              {{ item.EmploymentType | employmentTypeFormater }}</span
            > -->
            <el-select v-model="item.EmploymentType" placeholder="请选择">
              <el-option
                v-for="item in option3"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Note<br />
            填表说明
          </td>
          <td class="text-center" colspan="12">
            * 申请单位需落实受邀人不得有尚未处理完毕的违法或治安处罚。<br />
            * The applicant unit needs to confirm the invitee there must be no
            unsolved illegal cases.
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">
            Approval Record<br />
            审批记录
          </td>

          <td class="text-center" colspan="3">
            Approved by Department 单位/部门
          </td>
          <td class="text-center" colspan="3">
            Approved by Proejct Authorized 项目部
          </td>
          <td class="text-center" colspan="3">
            Approved by SNEMC HR Department 中东公司人力资源部
          </td>
          <td class="text-center" colspan="3">
            Approved by SNEMC Authorized 中东公司授权人
          </td>
        </tr>
        <tr>
          <td class="text-title" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbyDept" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbyProejctAuthorized" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCHRDept" />
            </div>
          </td>
          <td class="text-center" colspan="3">
            <div class="img-box">
              <img class="img" :src="ApprovedbySNEMCAuthorized" />
            </div>
          </td>
        </tr>

        <tr>
          <td class="text-center" colspan="14">
            The following content is only displayed online and is not in the
            print area 以下内容仅线上流转显示，非打印区域
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">
            Douments Upload<br />
            材料上传区域
          </td>
          <td class="text-left" colspan="12">
            * The support documents of invitation letters for employee's
            relative including employee’s IQAMA copy, relative’s passport copy,
            and application for Employees to Invite Relatives.<br />
            *
            员工携带家属邀请函资料包括员工IQAMA复印件、家属护照复印件以及携带家属申请。<br />
            * The support documents of invitation letters for others including
            company registration copy and passport copy of Invitee.<br />
            *
            其他人员邀请函资料要求：受邀人公司营业执照复印件和受邀人护照复印件。
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            <div class="shenpi shenpi-color" v-if="UploadingDocument1">
              <div @click="download(UploadingDocument1)">
                {{ UploadingDocument1 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(1)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment1"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 1<br />
                材料上传1<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument2">
              <div @click="download(UploadingDocument2)">
                {{ UploadingDocument2 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(2)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment2"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 2<br />
                材料上传2<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument3">
              <div @click="download(UploadingDocument3)">
                {{ UploadingDocument3 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(3)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment3"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 3<br />
                材料上传3<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="3">
            <div class="shenpi shenpi-color" v-if="UploadingDocument4">
              <div @click="download(UploadingDocument4)">
                {{ UploadingDocument4 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(4)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment4"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 4<br />
                材料上传4<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument5">
              <div @click="download(UploadingDocument5)">
                {{ UploadingDocument5 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(5)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment5"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 5<br />
                材料上传5<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  allPosition,
  getProjectsChildren,
  uploadAttachment,
} from "@/api/user";
import {
  getUsersCode,
  getGeneralBasic,
  addInvitationLetterApplication,
  updateInvitationLetterApplication,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    employmentTypeFormater(id) {
      const data = [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.name : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
  },
  data() {
    return {
      TableDate: null,
      ApplicationNumber: null,
      projectList: [],
      companyList: [],
      positionList: [],
      nationalityList: [],
      CostAllocationDetails: [],
      religionsList: [],
      subProjectList: [],
      ID: null,
      KeyID: null,
      CompanyNameinEnglish: null,
      CompanyCRNO: null,
      ProjectID: null,
      SubProjectID: null,
      CompanyID: null,
      BusinessScope: null,
      InvitationPuporse: null,
      PlaceofVisaApplication: null,
      ValidDuration: null,
      SingleMultipleEntry: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      EditState: null,
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        EmployeeID: null,
        NameinChinese: null,
        NameinEnglish: null,
        Gender: null,
        Nationality: null,
        DateofBirth: null,
        Age: null,
        Religion: null,
        JobProfession: null,
        PassportCode: null,
        PPValidDate: null,
        EmploymentType: null,
      },
      ApprovedbyDept: null,
      ApprovedbyProejctAuthorized: null,
      ApprovedbySNEMCHRDept: null,
      ApprovedbySNEMCAuthorized: null,

      validDurationOption: [
        {
          value: 1,
          label: "30天",
        },
        {
          value: 2,
          label: "90天",
        },
      ],
      singleMultipleEntryOption: [
        {
          value: 1,
          label: "单次",
        },
        {
          value: 2,
          label: "多次",
        },
      ],
      option3: [
        {
          value: 1,
          name: "正式职工",
        },
        {
          value: 2,
          name: "直聘",
        },
        {
          value: 3,
          name: "岗位外包",
        },
        {
          value: 4,
          name: "国内分包",
        },
        {
          value: 5,
          name: "岗位外包",
        },
        {
          value: 6,
          name: "外籍员工",
        },

        {
          value: 7,
          name: "虚拟沙化",
        },
        {
          value: 8,
          name: "常设劳务",
        },
        {
          value: 9,
          name: "当地分包",
        },
        {
          value: 10,
          name: "临时劳务",
        },
      ],
      genderList: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
      ],
    };
  },

  methods: {
    getNew(data1, data2) {
      this.projectList = data1;
      this.companyList = data2;
      this.TableDate = getNewDate();
      //获取各个信息
      console.log(JSON.parse(localStorage.getItem("userInfo")));
      if (localStorage.getItem("userInfo")) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo.Employee) {
          this.ProjectID = userInfo.Employee.ProjectID;
          this.SubProjectID = userInfo.Employee.SubProjectID;
          this.CompanyID = userInfo.Employee.CompanyID;
          this.getProjectsChildren();
        }
      }
    },
    addCostAllocation(EditState, InvitationLetterType) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        CompanyNameinEnglish: this.CompanyNameinEnglish,
        CompanyCRNO: this.CompanyCRNO,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        CompanyID: this.CompanyID,
        BusinessScope: this.BusinessScope,
        InvitationPuporse: this.InvitationPuporse,
        PlaceofVisaApplication: this.PlaceofVisaApplication,
        ValidDuration: this.ValidDuration,
        SingleMultipleEntry: this.SingleMultipleEntry,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        EditState,
        InvitationLetterType,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.EmployeeID) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });
      return data;
    },
    geteditData(data1, data2, params) {
      this.projectList = data1;
      this.companyList = data2;

      console.log("params", params);
      this.TableDate = params.CreateDate;
      this.ApplicationNumber = params.ApplicationNumber;

      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.CompanyNameinEnglish = params.CompanyNameinEnglish;
      this.CompanyCRNO = params.CompanyCRNO;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.CompanyID = params.CompanyID;
      this.BusinessScope = params.BusinessScope;
      this.InvitationPuporse = params.InvitationPuporse;
      this.PlaceofVisaApplication = params.PlaceofVisaApplication;
      this.ValidDuration = params.ValidDuration;
      this.SingleMultipleEntry = params.SingleMultipleEntry;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      this.getProjectsChildren();
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          params.Details[index].EmployeeID = params.Details[index].EmployeeCode;
          params.Details[index].Age = this.calculateAge(
            params.Details[index].DateofBirth
          );
          this.CostAllocationDetails[index] = JSON.parse(
            JSON.stringify(params.Details[index])
          );
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      this.ApprovedbyDept = params.ApprovedbyDept;
      this.ApprovedbyProejctAuthorized = params.ApprovedbyProejctAuthorized;
      this.ApprovedbySNEMCHRDept = params.ApprovedbySNEMCHRDept;
      this.ApprovedbySNEMCAuthorized = params.ApprovedbySNEMCAuthorized;
    },
    clearData() {
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
      this.ID = null;
      this.KeyID = null;
      this.CompanyNameinEnglish = null;
      this.CompanyCRNO = null;
      this.ProjectID = null;
      this.SubProjectID = null;
      this.CompanyID = null;
      this.BusinessScope = null;
      this.InvitationPuporse = null;
      this.PlaceofVisaApplication = null;
      this.ValidDuration = null;
      this.SingleMultipleEntry = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
      this.ApprovedbyDept = null;
      this.ApprovedbyProejctAuthorized = null;
      this.ApprovedbySNEMCHRDept = null;
      this.ApprovedbySNEMCAuthorized = null;
    },
    //通过录入护照号拿个人信息
    async changeEmployeeID(index) {
      const value = this.CostAllocationDetails[index].EmployeeID;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 0 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (!userInfo) {
        this.CostAllocationDetails[index].isUser = false;
        return;
      }
      this.CostAllocationDetails[index].isUser = true;
      let item = this.CostAllocationDetails[index];
      item.IsEmploymentOption = false;
      item.NameinChinese = userInfo.ChineseName;
      item.NameinEnglish = userInfo.EnglishName;
      item.Gender = userInfo.Gender;
      item.Nationality = userInfo.CountryID;
      item.DateofBirth = userInfo.DateofBirth;
      item.Age = this.calculateAge(item.DateofBirth);
      item.Religion = userInfo.Religion;
      item.JobProfession = userInfo.PositionID;
      item.PassportCode = userInfo.Passport.PPCode;
      item.PPValidDate = userInfo.Passport.PPValidDate;
      item.EmploymentType = userInfo.EmploymentType;
      if (!userInfo.EmploymentType) {
        item.EmploymentType = null;
        item.IsEmploymentOption = true;
      }
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      console.log("userInfo", userInfo);
    },
    //计算年龄
    calculateAge(birthday) {
      const today = new Date();
      const birthDate = new Date(birthday); // 将生日字符串转换为Date对象
      let age = today.getFullYear() - birthDate.getFullYear(); // 计算年份差
      // 检查是否已经过了生日
      const todayMonth = today.getMonth();
      const birthMonth = birthDate.getMonth();
      const todayDay = today.getDate();
      const birthDay = birthDate.getDate();
      if (
        todayMonth < birthMonth ||
        (todayMonth === birthMonth && todayDay < birthDay)
      ) {
        age--; // 如果还没过生日，年龄减一
      }
      return age;
    },
    getProjectsChildrenfa() {
      this.SubProjectID = null;
      this.getProjectsChildren();
    },
    //获取列表子项目
    getProjectsChildren() {
      getProjectsChildren({ id: this.ProjectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
    changeDateofBirth(index) {
      const DateofBirth = this.CostAllocationDetails[index].DateofBirth;
      this.CostAllocationDetails[index].Age = this.calculateAge(DateofBirth);
    },
    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      console.log("data");
      location.href = data;
    },
  },
  created() {
    this.TableDate = getNewDate();
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //国籍和宗教信仰
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
        this.religionsList = res.response.Religions;
        console.log("国籍", this.nationalityList);
      }
    });
    //岗位
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .shenpi {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    flex-direction: column;
    cursor: pointer;
    .shenpi-tips {
      color: #315efb;
      margin-left: 0;
      margin-top: 3px;
    }
  }
  .shenpi-color {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    flex-direction: row;
    color: #315efb;
    cursor: pointer;
    span {
      color: red;
      margin-left: 10px;
    }
  }
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-red {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      color: #ff0000;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 12px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  justify-content: center;
}
.shenpi-color {
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>